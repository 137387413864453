import axios from 'axios'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { BASE_URL } from './utils/config'
import { authHeader } from './utils/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyCZZAWZ0jP0AgqCekz6T3inbM8F9KuiTik',
  authDomain: 'srbt-a446d.firebaseapp.com',
  projectId: 'srbt-a446d',
  storageBucket: 'srbt-a446d.appspot.com',
  messagingSenderId: '1082988687738',
  appId: '1:1082988687738:web:e3ee822dd0d2c9533866c3',
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export default async function getFCMToken(swRegistration: any) {
  const token = await getToken(messaging, {
    vapidKey:
      'BN8yopBDyX51CKNszQkyUx69HCr0dJVSx1FeYUVNM5RMD8ruZyRzodPJZaBkOC7FUzlygRm8W8YRnDbnsS6jA10',
    serviceWorkerRegistration: swRegistration,
  })
  return token
}

export const initPushManager = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((swRegistration) => {
        return getFCMToken(swRegistration)
      })
      .then((token: any) => {
        const data: any = { data: token }
        return axios.post(`${BASE_URL}/api/subscribe-to-push`, data, {
          headers: authHeader(),
        })
      })
      .catch((er) => console.log('permission denied', er))
  }
}

onMessage(messaging, ({ data, fcmOptions }) => {
  const { title, vibrate, requireIntraction, ...rest } = data || {}
  const notification = new Notification(title, {
    ...rest,
    vibrate: vibrate ? JSON.parse(vibrate) : undefined,
    requireInteraction: requireIntraction == 'true',
    silent: Boolean(vibrate) === false,
  })
  notification.addEventListener('click', () => {
    window.location.href = data?.link || '/'
  })
  console.log(data, fcmOptions)
})
