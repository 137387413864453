import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useFetch } from '../../hooks/useFetch'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { ArtistContext } from '../../context/artistContext'

export default function Artists() {
  const theme = useTheme()
  const xl = useMediaQuery(theme.breakpoints.up('xl'))

  const { data }: any = useFetch('topartists', '/api/topartists', {})
  const { setArtist }: any = useContext(ArtistContext)
  return (
    <Box>
      <Box sx={{ bgcolor: 'black' }}>
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              width: 0,
            },
          }}
        >
          {data?.data?.map((item: any) => (
            <Box
              key={item?.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                m: 5,
              }}
            >
              <Link to={`/artists/${item?.id}`}>
                <img
                  width={xl ? 200 : 120}
                  height={xl ? 200 : 120}
                  alt={item?.name}
                  src={item?.image}
                  onClick={() => {
                    setArtist(item)
                  }}
                  loading="lazy"
                  style={{
                    borderRadius: xl ? 100 : 60,
                    objectFit: 'cover',
                  }}
                />
              </Link>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 'bold',
                  color: 'white',
                  pt: 2,
                  textAlign: 'center',
                }}
              >
                {item?.name}
              </Typography>
              <Link to={`/artists/${item?.id}`}>
                <Box
                  onClick={() => {
                    setArtist(item)
                  }}
                  sx={{ bgcolor: '#413D3E', borderRadius: 4, px: 2, py: 0.5 }}
                >
                  <Typography color="white">
                    {item?.musiccount} Songs
                  </Typography>
                </Box>
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
