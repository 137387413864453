import { Box, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SearchIcon from '@mui/icons-material/Search'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

import { useNavigate } from 'react-router-dom'

// function FeatureCard({
//   title,
//   sub,
//   icon,
//   size,
//   top,
// }: {
//   title: string
//   sub: string
//   icon: any
//   size: number
//   top: number
// }) {
//   const [bgColor, setBgColor] = useState('white')
//   const [displayIcon, setDisplayIcon] = useState(true)
//   const [textColor, setTextColor] = useState('black')

//   const handleMouseOver = () => {
//     setBgColor('#3D0505')
//     setDisplayIcon(false)
//     setTextColor('white')
//   }
//   const handleMouseOut = () => {
//     setBgColor('white')
//     setDisplayIcon(true)
//     setTextColor('black')
//   }
//   return (
//     <Grid
//       item
//       xs={12}
//       sm={12}
//       md={4}
//       sx={{
//         display: 'flex',
//         justifyContent: 'center',
//       }}
//     >
//       <Card
//         onMouseOver={handleMouseOver}
//         onMouseOut={handleMouseOut}
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           px: 5,
//           height: 300,
//           minWidth: 200,
//           width: 300,
//           maxWidth: 700,
//           bgcolor: bgColor,
//         }}
//       >
//         <Box
//           sx={{
//             height: 100,
//             width: size,
//             pt: top,
//             display: displayIcon ? '' : 'none',
//           }}
//         >
//           <Lottie animationData={icon} loop={true} />
//         </Box>
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             py: 5,
//           }}
//         >
//           <Typography
//             variant="h4"
//             color={textColor}
//             sx={{ fontWeight: 'bold' }}
//           >
//             {title}
//           </Typography>
//           <Typography color={textColor}>{sub}</Typography>
//         </Box>
//       </Card>
//     </Grid>
//   )
// }

export default function Header({
  isNav,
  page,
}: {
  isNav?: boolean
  page?: string
}) {
  const navigate = useNavigate()
  return (
    <>
      {/* <Box
        sx={{
          display: { xs: 'none', md: 'none' },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 70px)',
          background: '#8DC63F',
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: 'bold', color: 'white' }}>
          SRBT
        </Typography>
        <Typography variant="h6" sx={{ color: 'white' }}>
          Make Your Calls Personalized & Beautiful
        </Typography>
        <Box sx={{ py: 5 }}>
          <Grid container spacing={8}>
            <FeatureCard
              title="Make"
              sub="Your Call Enjoyable"
              icon={Call}
              size={100}
              top={3}
            />
            <FeatureCard
              title="Send"
              sub="To Your Loved Ones"
              icon={Gift}
              size={200}
              top={0}
            />
            <FeatureCard
              title="All"
              sub="Kinds of Music"
              icon={Star}
              size={70}
              top={5}
            />
          </Grid>
        </Box>
      </Box> */}
      {page && (
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
            justifyContent: isNav ? 'space-between' : 'flex-start',
            gap: 3,
            px: 2,
            height: 70,
            bgcolor: '#000000',
          }}
        >
          {!isNav && (
            <IconButton
              aria-label="back"
              name="back"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon sx={{ color: 'white' }} />
            </IconButton>
          )}
          <Typography color="white" variant="h6">
            {page}
          </Typography>
          {isNav && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() => navigate('/search')}
                name="search"
                aria-label="search"
              >
                <SearchIcon sx={{ color: 'white' }} />
              </IconButton>
              <IconButton name="notification" aria-label="notification">
                <NotificationsNoneIcon sx={{ color: 'white' }} />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
