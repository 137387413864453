import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

function PromptComponent({ prompt, setState, promptToInstall }: any) {
  const [isVisible, setVisibleState] = useState(false)
  useEffect(() => {
    if (prompt) {
      setVisibleState(true)
    }
  }, [prompt])

  if (!isVisible) return <div />

  return (
    <Dialog
      open={isVisible}
      onClose={() => {
        setVisibleState(false)
        setState(null)
      }}
    >
      <DialogTitle fontWeight="bold">Add To Homescreen</DialogTitle>
      <DialogContent>
        You can open this web application from your devices homescreen like a
        native application
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'orange' }}
          onClick={() => {
            window.localStorage.setItem('desktopIcon', 'NO')
            setVisibleState(false)
            setState(null)
          }}
        >
          No
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'green' }}
          onClick={() => {
            promptToInstall()
            setVisibleState(false)
            setState(null)
          }}
        >
          Install
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const InstallPrompt = React.memo(PromptComponent)

export default InstallPrompt
