import { useQuery } from 'react-query'
import { authHeader } from '../utils/auth'
import axios from 'axios'
import { BASE_URL } from '../utils/config'

export function useFetch(
  name: string,
  endpoint?: string,
  params?: any,
  options?: any
) {
  const { data, ...rest } = useQuery(
    name,
    () => {
      return axios.get(`${BASE_URL}${endpoint}`, {
        headers: authHeader(),
        params: { params: JSON.stringify(params) },
      })
    },
    options
  )
  return { data: data?.data, ...rest }
}
