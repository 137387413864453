import { Suspense, lazy, useEffect, useState } from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'
import { SnackbarProvider } from 'notistack'
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Slide,
  ThemeProvider,
  Typography,
} from '@mui/material'
import theme from './Theme'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Layout from './components/layout/Layout'
import { CheckAuth } from './components/auth/CheckAuth'
import { ToneContext } from './context/toneContext'
import { ArtistContext } from './context/artistContext'
import { PlaylistContext } from './context/playlistContext'
const Signup = lazy(() => import('./pages/Signup'))
const Verify = lazy(() => import('./pages/Verify'))
const Login = lazy(() => import('./pages/Login'))
const Artists = lazy(() => import('./pages/Artists'))
const Rank = lazy(() => import('./pages/Rank'))
const Purchase = lazy(() => import('./pages/Purchase'))
const Reward = lazy(() => import('./pages/Reward'))
const ArtistInfo = lazy(() => import('./pages/ArtistInfo'))
const Account = lazy(() => import('./pages/Account'))
const Subscription = lazy(() => import('./pages/Subscriptions'))
import Play from './pages/Play'
const SuccessPage = lazy(() => import('./pages/SuccessPage'))
const Search = lazy(() => import('./pages/Search'))
const History = lazy(() => import('./pages/History'))
const Purchases = lazy(() => import('./pages/Purchases'))
const WaitingPurchase = lazy(() => import('./pages/WaitingPurchase'))
const MusicDetails = lazy(() => import('./pages/MusicDetails'))
const Terms = lazy(() => import('./pages/Terms'))
const Privacy = lazy(() => import('./pages/Privacy'))
import axios from 'axios'
import { BASE_URL, merchantAppId } from './utils/config'
import update from './assets/images/update.png'
import { Capacitor } from '@capacitor/core'
import PageLoading from './components/PageLoading'

const queryClient = new QueryClient()
function App() {
  const [tone, setTone] = useState()
  const [artist, setArtist] = useState()
  const [tempPlayList, setTempPlayList] = useState({}) as any
  const [updateInfo, setUpdateInfo] = useState() as any
  const [contextData, setContextData] = useState() as any
  const [endPoint, setEndPoint] = useState() as any
  const versionCode = 4

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/checkupdate`)
      .then((res) => setUpdateInfo(res?.data?.data?.[0]))
      .catch((e) => console.log(e))
  }, [])
  // @ts-ignore
  window.handleinitDataCallback = (token: string) => {
    console.log('Token', token)
    requestAuthData(token)
  }

  const obj = JSON.stringify({
    functionName: 'js_fun_h5GetAccessToken',
    params: {
      appid: merchantAppId,
      functionCallBackName: 'handleinitDataCallback',
    },
  })
  useEffect(() => {
    // @ts-ignore
    window?.consumerapp?.evaluate(obj)
  }, [])

  const requestAuthData = (token: string) => {
    console.log({ token })
    axios
      .post(
        BASE_URL + '/api/h5token',
        { authToken: token },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res: any) => {
        console.log('res', { res })
        localStorage.setItem('Customer', JSON.stringify(res?.data?.data))
      })
      .catch((ex: any) => {
        console.log('Exception found', ex)
      })
      .finally(() => {
        console.log('Finally')
      })
  }

  if (updateInfo?.versionCode > versionCode && Capacitor?.isNativePlatform()) {
    return (
      <Box sx={{ width: '100vw', maxHeight: '100vh', overflowY: 'hidden' }}>
        <Box sx={{ width: '100vw' }}>
          <Box
            component="img"
            src={update}
            sx={{ width: '100%', height: '50vh' }}
            alt="update"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 3,
            px: 3,
            height: '45vh',
          }}
        >
          <Typography
            variant="h5"
            color="#2F3641"
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            New Update is Available
          </Typography>
          {updateInfo?.isForce ? (
            <Typography color="#AAB0B5" sx={{ textAlign: 'center' }}>
              The current version of the app is no longer supported. We
              apologize for any inconvinience we may have caused you.
            </Typography>
          ) : (
            <Typography color="#AAB0B5" sx={{ textAlign: 'center' }}>
              There is a new update available. Please click update if u want to
              get the new features.
            </Typography>
          )}
          {updateInfo?.isForce ? (
            <Button
              variant="contained"
              sx={{ bgcolor: '#6BD1E1', mt: 2 }}
              fullWidth
              href="https://play.google.com/store/apps/details?id=com.srbt.app"
            >
              Update Now
            </Button>
          ) : (
            <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                sx={{ bgcolor: '#6BD1E1', mt: 2 }}
                fullWidth
                href="https://play.google.com/store/apps/details?id=com.srbt.app"
              >
                Update Now
              </Button>
              <Button
                variant="contained"
                sx={{ bgcolor: 'gray', mt: 2 }}
                fullWidth
                onClick={() => setUpdateInfo(null)}
              >
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    )
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          TransitionComponent={Slide}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ToneContext.Provider value={{ tone, setTone }}>
              <ArtistContext.Provider value={{ artist, setArtist }}>
                <PlaylistContext.Provider
                  value={{
                    tempPlayList,
                    setTempPlayList,
                    contextData,
                    setContextData,
                    endPoint,
                    setEndPoint,
                  }}
                >
                  <Router>
                    <Routes>
                      <Route
                        path="/signup"
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Signup />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/login"
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Login />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/verify/:phoneNumber"
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Verify />
                          </Suspense>
                        }
                      />
                      <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route
                          path="artists"
                          element={
                            <Suspense fallback={<PageLoading />}>
                              <Artists />
                            </Suspense>
                          }
                        />
                        <Route
                          path="ranking"
                          element={
                            <Suspense fallback={<PageLoading />}>
                              <Rank />
                            </Suspense>
                          }
                        />
                        <Route
                          path="artists/:id"
                          element={
                            <Suspense fallback={<PageLoading />}>
                              <ArtistInfo />
                            </Suspense>
                          }
                        />
                        <Route path="play" element={<Play />} />
                        <Route
                          path="search"
                          element={
                            <Suspense fallback={<CircularProgress />}>
                              <Search />
                            </Suspense>
                          }
                        />
                        <Route
                          path="waitingpurchase"
                          element={
                            <Suspense fallback={<CircularProgress />}>
                              <WaitingPurchase />
                            </Suspense>
                          }
                        />
                        <Route
                          path="music/:id"
                          element={
                            <Suspense fallback={<CircularProgress />}>
                              <MusicDetails />
                            </Suspense>
                          }
                        />
                      </Route>
                      <Route
                        path="terms"
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Terms />
                          </Suspense>
                        }
                      />
                      <Route
                        path="privacy"
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Privacy />
                          </Suspense>
                        }
                      />
                      <Route
                        path="/"
                        element={
                          <CheckAuth>
                            <Layout />
                          </CheckAuth>
                        }
                      >
                        <Route
                          path="purchase"
                          element={
                            <Suspense fallback={<CircularProgress />}>
                              <Purchase />
                            </Suspense>
                          }
                        />
                        {/* <Route
                              path="dashboard/:id"
                              element={<Dashboard />}
                            /> */}
                        <Route
                          path="reward"
                          element={
                            <Suspense fallback={<PageLoading />}>
                              <Reward />
                            </Suspense>
                          }
                        />
                        <Route
                          path="account"
                          element={
                            <Suspense fallback={<PageLoading />}>
                              <Account />
                            </Suspense>
                          }
                        />
                        <Route
                          path="subscription/:id"
                          element={
                            <Suspense fallback={<CircularProgress />}>
                              <Subscription />
                            </Suspense>
                          }
                        />
                        <Route
                          path="history/:id"
                          element={
                            <Suspense fallback={<CircularProgress />}>
                              <History />
                            </Suspense>
                          }
                        />
                        <Route
                          path="purchases/:id"
                          element={
                            <Suspense fallback={<CircularProgress />}>
                              <Purchases />
                            </Suspense>
                          }
                        />
                        <Route
                          path="success"
                          element={
                            <Suspense fallback={<CircularProgress />}>
                              <SuccessPage />
                            </Suspense>
                          }
                        />
                      </Route>
                    </Routes>
                  </Router>
                </PlaylistContext.Provider>
              </ArtistContext.Provider>
            </ToneContext.Provider>
          </ThemeProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    )
  }
}
export default App
