import { Suspense, lazy, memo, useEffect, useRef, useState } from 'react'
import memoize from 'memoize-one'
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material'
const WebPlayer = lazy(() => import('../components/Play'))
import { FixedSizeList as List, areEqual } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import PageLoading from '../components/PageLoading'
import { debouncedHandler } from '../components/layout/Layout'
import { BASE_URL } from '../utils/config'
import { authHeader } from '../utils/auth'
import axios from 'axios'
import { useInfiniteQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { useFetch } from '../hooks/useFetch'
const AudioCard = lazy(() => import('../components/PlayCard'))

export function debounce(func: (arg: any) => void, timeout = 20) {
  let timer: any
  return (arg: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => func(arg), timeout)
  }
}

const ItemRenderer = memo(function ItemRenderer({ style, data, index }: any) {
  return (
    <Suspense fallback={<PageLoading />}>
      <AudioCard
        style={style}
        active={data?.active}
        index={index}
        key={data?.data?.[index]?.id}
        data={data?.data?.[index]}
        isPaused={data?.isPaused}
      />
    </Suspense>
  )
}, areEqual)

let scrollend = false
const toggle = (val: boolean) => (
  console.log({ scrollendd: scrollend }), (scrollend = val)
)

const createItemData = memoize((data: any, active: any, current: any) => ({
  data,
  active,
  current,
}))

const ListContainer = ({
  ref,
  playlist,
  hasNextPage,
  fetchNextPage,
  height,
  width,
  md,
  ts,
}: any) => {
  const current = useRef(playlist?.[0])
  const snapContainer = useRef()
  const [active, setActive] = useState(0)
  const playlistLen = playlist?.length
  const len = useRef(playlistLen)
  const listRef = useRef()
  let touchStartTime: any
  let previousY: number
  let scrollTop: any

  len.current = playlistLen
  if (playlistLen <= active + 3 && hasNextPage) {
    fetchNextPage()
  }
  if (current?.current) current.current = playlist?.[active]

  if (ref?.current) {
    const h = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )
    //@ts-ignore
    scrollTop = ref?.current?.scrollTop
    if (scrollTop < -h / 2) {
      setActive((prev) => {
        if (prev > 0) {
          const active = prev - 1
          // @ts-ignore
          listRef.current.scrollToItem(active)
          return active
        }
        return prev
      })
    } else if (scrollTop > h / 2) {
      setActive((prev) => {
        if (prev < len.current - 1) {
          const active = prev + 1
          // @ts-ignore
          listRef.current.scrollToItem(active)
          return active
        }
        return prev
      })
    }
  }

  const scroll = (speed: number) => {
    if (speed > 200) {
      setActive((prev) => {
        if (prev > 0) {
          const active = prev - 1
          // @ts-ignore
          listRef.current.scrollToItem(active, 'start')
          return active
        }
        return prev
      })
    } else if (speed < -200) {
      setActive((prev: any) => {
        if (prev < len.current - 1) {
          const active = prev + 1
          // @ts-ignore
          listRef.current.scrollToItem(active, 'start')
          return active
        }
        return prev
      })
    } else {
      setActive((prev) => {
        // @ts-ignore
        listRef.current.scrollToItem(prev, 'start')
        return prev
      })
    }
  }

  const isScrollend = () => {
    return {
      scrollend,
      toggle,
    }
  }
  useEffect(() => {
    if (!md && snapContainer.current) {
      // @ts-ignore
      snapContainer.current.addEventListener(
        'DOMMouseScroll',
        (e: any) => {
          e.preventDefault()
        },
        false
      ) //@ts-ignore
      snapContainer.current.addEventListener(
        'mousewheel',
        (e: any) => {
          e.preventDefault()
        },
        false
      ) //@ts-ignore
      snapContainer.current.addEventListener(
        'wheel',
        (e: any) => {
          e.preventDefault()
        },
        false
      ) //@ts-ignore
      snapContainer.current.addEventListener(
        'touchmove',
        (e: any) => {
          e.preventDefault()
          // handleScroll = true
          const currentY = e.touches?.[0].clientY
          const delta = previousY - currentY
          previousY -= delta
          if (!scrollend) {
            // @ts-ignore
            snapContainer.current.scrollBy(0, delta)
          }
        },
        false
      ) //@ts-ignore
      snapContainer?.current.addEventListener('touchstart', (e: any) => {
        ts = e.touches?.[0].clientY
        touchStartTime = e.timeStamp
        previousY = ts
        scrollend = false
      }) //@ts-ignore
      snapContainer?.current.addEventListener('touchend', (e: any) => {
        // scroller(e, playlistLen.current)
        const touchend = e.changedTouches?.[0].clientY
        const changeY = ts - touchend
        const changeT = (touchStartTime - e.timeStamp) / 1000
        const speed = changeY / changeT
        const { toggle } = isScrollend()
        toggle(true)

        requestAnimationFrame(() => scroll(speed))
      })
    }
  }, [])

  const itemData = createItemData(playlist, active, current)

  return (
    <List
      itemData={itemData}
      itemKey={(i: number) => playlist?.[i]?.id}
      height={height}
      width={width}
      itemCount={playlist?.length}
      itemSize={height}
      outerRef={snapContainer} // @ts-ignore
      ref={listRef}
      innerRef={ref}
      overscanCount={0}
      style={{
        overflowY: 'hidden',
      }}
    >
      {ItemRenderer}
    </List>
  )
}

export default function Play() {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  let ts: number

  // use params ==> query string
  const [searchParams] = useSearchParams()
  const url = searchParams.get('url')
  const musicId = searchParams.get('id')
  const sorting = JSON.parse(searchParams.get('sorting') || '[]')

  // this compont fetches
  const fetchMusics = ({ pageParam }: any) => {
    const { page = 1, pageSize = 5 } = pageParam || {}

    const data = url
      ? axios.get(`${BASE_URL}/api/` + url, {
          headers: authHeader(),
          params: {
            params: JSON.stringify({
              pagination: { pageIndex: page, pageSize },
              sorting,
            }),
          },
        })
      : undefined

    data?.then((musics: any) => {
      // Post to service worker to fetch audio
      debouncedHandler(musics)
    })
    return data
  }

  const {
    data: firstMusic,
    isFetching: fetchingFirstMusic,
    refetch,
  } = useFetch('musicId', '/api/music/' + musicId, undefined, {
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [searchParams])

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: [`${url}`],
    queryFn: fetchMusics,
    getNextPageParam: (lastPage) => {
      const { page: lastPageNo, total } = lastPage?.data?.metaData || {}
      const hasNextPage = total - (lastPageNo + 1) * 5 > 0

      return hasNextPage
        ? {
            page: lastPageNo + 1,
            pageSize: 5,
            sorting,
          }
        : undefined
    },
    refetchOnWindowFocus: false,
  })

  const isdone = !isLoading && !fetchingFirstMusic

  if (!isdone) return <PageLoading />

  const { pages } = data || {}
  const musics = pages?.reduce((prev: any, curr) => {
    const currentPage = curr?.data?.data || undefined
    if (currentPage) return [...prev, ...currentPage]
    return prev
  }, [])
  const filteredPlaylist =
    musics?.filter((el: any) => el.id !== firstMusic?.data?.id) || []
  const playlist = [firstMusic?.data, ...filteredPlaylist]

  return (
    <Box>
      {!md && playlist?.length && (
        <Box
          sx={{
            height: 'calc(100vh - 70px)',
          }}
        >
          <AutoSizer>
            {({ height, width }: any) => (
              <ListContainer
                height={height}
                width={width}
                playlist={playlist}
                md={md}
                ts={ts}
                fetchNextPage={() => {
                  if (!isFetching) fetchNextPage()
                }}
                hasNextPage={hasNextPage}
              />
            )}
          </AutoSizer>
        </Box>
      )}

      {md && playlist?.length && (
        <Box
          sx={{
            height: 'calc(100vh - 70px)',
            overflowY: 'scroll',
            scrollSnapType: 'y mandatory',
            scrollBehavior: 'smooth',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: 0,
            },
            bgcolor: '#343434',
          }}
        >
          {playlist?.map((item: any) => (
            <Suspense key={item?.id} fallback={<CircularProgress />}>
              <WebPlayer data={item} />
            </Suspense>
          ))}
        </Box>
      )}
    </Box>
  )
}
