import { Box } from '@mui/material'
import Header from '../components/home/Header'
import TopSellings from '../components/home/TopSellings'
import Featured from '../components/home/Featured'
import New from '../components/home/New'
import Artists from '../components/home/Artists'
import Recommended from '../components/home/Recommended'
import Footer from '../components/home/Footer'
import { initPushManager } from '../firebase'

export default function Home() {
  initPushManager()
  return (
    <Box>
      <Header page="SRBT" isNav />
      <Box
        sx={{
          overflowY: 'scroll',
          height: { xs: 'calc(100svh - 140px)', md: 'calc(100svh - 70px)' },
          '&::-webkit-scrollbar': {
            width: 0,
          },
        }}
      >
        <TopSellings />
        <Featured />
        <New />
        <Artists />
        <Recommended />
        <Footer />
      </Box>
    </Box>
  )
}
