// import { Box, Grid, Typography } from '@mui/material'
// import Ignite from '../../assets/images/Ignite.svg'
// import PhoneIcon from '@mui/icons-material/Phone'
// import EmailIcon from '@mui/icons-material/Email'
// import LocationOnIcon from '@mui/icons-material/LocationOn'
// import Creative from '../../assets/images/creative.svg'
// import Twitter from '../../assets/images/twitter.svg'
// import Linkedin from '../../assets/images/linkedin.svg'
// import Tiktok from '../../assets/images/tiktok.svg'
// import Youtube from '../../assets/images/youtube.svg'
// import Instagram from '../../assets/images/instagram.svg'
// import Telegram from '../../assets/images/telegram.svg'
// import Facebook from '../../assets/images/facebook.svg'

// function Footer() {
//   return (
//     <Box
//       sx={{
//         bgcolor: '#F5F5F5',
//         maxWidth: '100vw',
//         display: { xs: 'none', md: 'block' },
//       }}
//     >
//       <Grid container>
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           md={4}
//           sx={{
//             p: 5,
//             display: 'flex',
//             justifyContent: 'center',
//           }}
//         >
//           <Box>
//             <Box component="img" alt="Ignite" src={Ignite} />
//             <Box sx={{ pt: 3 }}>
//               <Typography>
//                 sewasew is a global Ethiopian music streaming platform bringing
//                 fans closer to artists through unique experiences.
//               </Typography>
//             </Box>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           md={4}
//           sx={{ p: 5, display: 'flex', justifyContent: 'center' }}
//         >
//           <Box>
//             <Box>
//               <Typography
//                 color="primary"
//                 variant="h6"
//                 sx={{ fontWeight: 'bold' }}
//               >
//                 CONTACT US
//               </Typography>
//             </Box>
//             <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
//               <Box sx={{ pr: 3 }}>
//                 <PhoneIcon />
//               </Box>
//               <Box>
//                 <Typography color="primary">+(251)9112233445</Typography>
//                 <Typography color="primary">+(251)9112233445</Typography>
//               </Box>
//             </Box>
//             <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
//               <Box sx={{ pr: 3 }}>
//                 <EmailIcon />
//               </Box>
//               <Box>
//                 <Typography color="primary">support@2f-capital.com</Typography>
//                 <Typography color="primary">
//                   www.sewasewmusic.com/support
//                 </Typography>
//               </Box>
//             </Box>
//             <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
//               <Box sx={{ pr: 3 }}>
//                 <LocationOnIcon />
//               </Box>
//               <Box>
//                 <Typography color="primary">
//                   Sheger Bldg, 6th floor, Bole A.A, ETH
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={4}
//           sx={{ p: 5, display: 'flex', justifyContent: 'center' }}
//         >
//           <Box
//             sx={{
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//             }}
//           >
//             <Box component="img" alt="Creative" src={Creative} width={240} />
//             <Box sx={{ bgcolor: '#FFC905', width: 240, p: 1, borderRadius: 1 }}>
//               <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
//                 A CREATIVE KINGDOM PROJECT
//               </Typography>
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//       <Grid container sx={{ bgcolor: '#E4E4E4' }}>
//         <Grid item xs={12} sm={12} md={4} sx={{ px: 5, py: 3 }}>
//           <Box sx={{ display: 'flex', justifyContent: 'center' }}>
//             <Box
//               component="img"
//               alt="Faceboook"
//               src={Facebook}
//               sx={{ px: 1 }}
//             />
//             <Box component="img" alt="Twitter" src={Twitter} sx={{ px: 1 }} />
//             <Box component="img" alt="Linkedin" src={Linkedin} sx={{ px: 1 }} />
//             <Box component="img" alt="Tiktok" src={Tiktok} sx={{ px: 1 }} />
//             <Box
//               component="img"
//               alt="Instagram"
//               src={Instagram}
//               sx={{ px: 1 }}
//             />
//             <Box component="img" alt="Telegram" src={Telegram} sx={{ px: 1 }} />
//             <Box component="img" alt="Youtube" src={Youtube} sx={{ px: 1 }} />
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={12} md={4} sx={{ px: 5, py: 3 }}>
//           <Box sx={{ display: 'flex', justifyContent: 'center' }}>
//             <Typography>@2022 Sewasew Multimedia</Typography>
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={12} md={4} sx={{ px: 5, py: 3 }}>
//           <Box sx={{ display: 'flex', justifyContent: 'center' }}>
//             <Typography>Terms & Conditions</Typography>
//             <Typography sx={{ pl: 3 }}>Privacy Policy</Typography>
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   )
// }

// export default Footer

import {
  FacebookRounded,
  Instagram,
  LinkedIn,
  Mail,
  Phone,
  Place,
  Telegram,
  Twitter,
  YouTube,
} from '@mui/icons-material'
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Link as MuiLink,
  Divider,
} from '@mui/material'
import playstore from '../../assets/images/playstore.svg'
import appstore from '../../assets/images/appstore.svg'
import creative from '../../assets/images/creative copy.svg'
import Tiktok from '../../assets/images/tiktok.svg'

const ENV = {
  REACT_APP_DOWNLOAD_IOS:
    'https://apps.apple.com/us/app/sewasew-music/id1623030941',
  REACT_APP_DOWNLOAD_ANDROID:
    'https://play.google.com/store/apps/details?id=com.sewasewmusic.music',
  FACEBOOK: 'https://www.facebook.com/sewasewmultimedia',
  TWITTER: 'https://twitter.com/sewasewMmedia',
  LINKEDIN: 'https://www.linkedin.com/in/sewasew-multimedia-13a456237/',
  TELEGRAM: 'https://web.telegram.org/k/#@sewasewmultimedia_bot',
  YOUTUBE: 'https://www.youtube.com/channel/UCmpvWO3dhqieY9TNdcFCYsw',
  INSTAGRAM: 'https://www.instagram.com/sewasewmultimedia',
  TIKTOK: 'https://vm.tiktok.com/ZMF2dg3x3/',
  SEWASEWMULTIMEDIA: 'https://sewasewmultimedia.com/',
}

const SocialMediaIcons = [
  { Icon: FacebookRounded, Link: ENV.FACEBOOK },
  { Icon: Twitter, Link: ENV.TWITTER },
  { Icon: LinkedIn, Link: ENV.LINKEDIN },
  { Icon: Telegram, Link: ENV.TELEGRAM },
  { Icon: YouTube, Link: ENV.YOUTUBE },
  { Icon: Instagram, Link: ENV.INSTAGRAM },
]

const getYear = () => {
  return new Date().getFullYear()
}

function Container({ title, children, ...rest }: any) {
  return (
    <Box {...rest}>
      <Typography
        textTransform="uppercase"
        variant="subtitle1"
        fontWeight="bold"
      >
        {title}
      </Typography>
      <Box sx={{ pt: 1 }}>{children}</Box>
    </Box>
  )
}

function ContactItem({ Icon, children }: any) {
  return (
    <Box display="flex" alignItems="center">
      <IconButton name="icon" aria-label="icon" sx={{ pl: '0px' }}>
        {Icon}
      </IconButton>
      <Box>{children}</Box>
    </Box>
  )
}

export default function Footer() {
  return (
    <Box
      sx={{
        maxWidth: '100vw',
        overflowX: 'hidden',
        display: { xs: 'none', md: 'block' },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-around"
        sx={{ bgcolor: '#EFEFEF', py: 1.5, px: 5 }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={3}>
            <Container title="sewasew multimedia">
              <Typography
                fontSize=".9em"
                sx={{ lineHeight: 1.2, pr: { xs: 0, sm: 5 } }}
              >
                Sewasew multimedia is a rights management, record label and
                streaming platform envisioned to foster the accelerated
                development of Africas creative economy
              </Typography>
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Container title="contact us">
              <ContactItem Icon={<Phone />}>
                <Typography fontSize=".9em" sx={{ lineHeight: 1.2 }}>
                  + (251) 907 4278 84
                </Typography>
              </ContactItem>
              <ContactItem Icon={<Mail />}>
                <Typography fontSize=".9em" sx={{ lineHeight: 1.2 }}>
                  support@2f-capital.com
                </Typography>
                <Typography fontSize=".9em" sx={{ lineHeight: 1.2 }}>
                  https://sewasewmultimedia.com/support
                </Typography>
              </ContactItem>
              <ContactItem Icon={<Place />}>
                <Typography fontSize=".9em" sx={{ lineHeight: 1.2 }}>
                  Sheger Bldg, 6th floor, Bole A.A, ETH
                </Typography>
              </ContactItem>
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Container title="download app">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', sm: 'column' },
                  gap: { xs: 5, sm: 1.5 },
                }}
              >
                <a
                  target="_blank"
                  href={ENV.REACT_APP_DOWNLOAD_ANDROID}
                  rel="noreferrer"
                >
                  <Box
                    component="img"
                    src={playstore}
                    alt="google play"
                    sx={{ width: 150 }}
                  />
                </a>
                <a
                  target="_blank"
                  href={ENV.REACT_APP_DOWNLOAD_IOS}
                  rel="noreferrer"
                >
                  <Box
                    component="img"
                    src={appstore}
                    alt="app store"
                    sx={{ width: 150 }}
                  />
                </a>
              </Box>
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Container title=" ">
              <Box
                src={creative}
                component="img"
                alt="creative kingdom"
                sx={{ width: { xs: '80vw', sm: 270 } }}
              />
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          minHeight: 43,
          px: { sm: 5, md: 10 },
          bgcolor: 'lightgray',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid container sx={{ my: { xs: 1, sm: 0 } }}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                {SocialMediaIcons.map(({ Icon, Link }: any, index) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      ':hover': { transform: 'scale(1.1)' },
                    }}
                  >
                    <MuiLink href={Link} target="_blank" rel="noreferrer">
                      <Icon
                        sx={{
                          color: 'black',
                        }}
                      />
                    </MuiLink>
                  </Grid>
                ))}
                <Grid
                  item
                  sx={{
                    cursor: 'pointer',
                    ':hover': { transform: 'scale(1.1)' },
                  }}
                >
                  <MuiLink href={ENV.TIKTOK} target="_blank" rel="noreferrer">
                    <Box
                      component="img"
                      src={Tiktok}
                      alt="tiktok"
                      sx={{ width: 23 }}
                    />
                  </MuiLink>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={0}
            sx={{ display: { xs: 'block', sm: 'none' } }}
          >
            <Divider flexItem />
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: { xs: 1, sm: 0 },
            }}
          >
            <Box>
              <Typography
                sx={{ fontWeight: 'bold', fontSize: { xs: 12, sm: 15 } }}
              >
                &copy;{getYear()} Sewasew Multimedia
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: { xs: 1, sm: 0 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: { xs: 'center', sm: 'flex-start' },
              }}
            >
              <MuiLink
                href="/terms"
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: 'none', color: 'black' }}
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: { xs: 12, sm: 15 } }}
                >
                  Terms of Service
                </Typography>
              </MuiLink>
              <MuiLink
                href="/privacy"
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: 'none', color: 'black' }}
              >
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: { xs: 12, sm: 15 } }}
                >
                  Privacy Policy
                </Typography>
              </MuiLink>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
