import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#4B1314',
    },
    secondary: {
      main: '#000000',
    },
    info: {
      main: '#8DC63F',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: ({ ownerState }) => ({
  //         ...(ownerState.variant === 'contained' && {
  //           background:
  //             'transparent linear-gradient(180deg, #7ACB46 0%, #F2ED4F 100%) 0% 0% no-repeat padding-box',
  //           color: '#fff',
  //         }),
  //       }),
  //     },
  //   },
  // },
})

export default theme
