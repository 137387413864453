import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import { Typography } from '@mui/material'
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import { useContext } from 'react'
import { ToneContext } from '../context/toneContext'

export default function RbtShare({ open, closeDrawer }: any) {
  const { tone } = useContext(ToneContext) as any
  const list = () => (
    <Box
      sx={{ width: 'auto', px: 3 }}
      role="presentation"
      onClick={closeDrawer}
      onKeyDown={closeDrawer}
    >
      <Box sx={{ p: 3 }}>
        <Typography sx={{ fontWeight: 'bold' }}>Share RBT</Typography>
      </Box>
      <Divider />
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#7ACB46" />
          <stop offset={1} stopColor="#F2ED4F" />
        </linearGradient>
      </svg>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pl: 5,
        }}
      >
        <Box sx={{ py: 1 }}>
          <FacebookShareButton
            url={`https://srbt.et/music/${tone?.id}`}
            quote={'Try out this tone from Sewasew CRBT'}
            hashtag={`#Sewasew #CRBT`}
          >
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <FacebookIcon size={32} round />
              <Typography sx={{ fontWeight: 'bold' }}>Facebook</Typography>
            </Box>
          </FacebookShareButton>
        </Box>
        <Box sx={{ py: 1 }}>
          <WhatsappShareButton
            url={`https://srbt.et/music/${tone?.id}`}
            title={
              tone?.title.charAt(0).toUpperCase() +
              tone?.title.slice(1).toLowerCase()
            }
          >
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <WhatsappIcon size={32} round />
              <Typography sx={{ fontWeight: 'bold' }}>Whatsapp</Typography>
            </Box>
          </WhatsappShareButton>
        </Box>
        <Box sx={{ py: 1 }}>
          <TwitterShareButton
            url={`https://srbt.et/music/${tone?.id}`}
            title={
              tone?.title.charAt(0).toUpperCase() +
              tone?.title.slice(1).toLowerCase()
            }
          >
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <TwitterIcon size={32} round />
              <Typography sx={{ fontWeight: 'bold' }}>Twitter</Typography>
            </Box>
          </TwitterShareButton>
        </Box>
        <Box sx={{ py: 1 }}>
          <TelegramShareButton
            url={`https://srbt.et/music/${tone?.id}`}
            title="CRBT"
          >
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <TelegramIcon size={32} round />
              <Typography sx={{ fontWeight: 'bold' }}>Telegram</Typography>
            </Box>
          </TelegramShareButton>
        </Box>
      </Box>
    </Box>
  )

  return (
    <Drawer anchor="bottom" open={open} onClose={closeDrawer}>
      {list()}
    </Drawer>
  )
}
