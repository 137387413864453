import { Box, Card, IconButton, Skeleton, Typography } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import ShareIcon from '@mui/icons-material/Share'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { useContext, useState } from 'react'
import RbtType from '../RbtType'
import { ToneContext } from '../../context/toneContext'
import { useNavigate } from 'react-router-dom'
import { useFetch } from '../../hooks/useFetch'
import RbtShare from '../RBTShare'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import styled from '@emotion/styled'
import Guide from '../Guide'

function TopSellingWeb({
  item,
  setIsGift,
  setTone,
  setOpen,
  setAnchorEl,
  playing,
  setPlaying,
  setShareOpen,
}: any) {
  const navigate = useNavigate()
  return (
    <Card
      key={item?.id}
      sx={{
        minWidth: { xs: '90vw', md: 350, xl: 500 },
        height: { xs: 250, md: 300, xl: 400 },
        borderRadius: 5,
        bgcolor: 'black',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${item?.poster})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            backgroundColor: ' rgba(0,0,0,0.5)',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {item?.id === playing ? (
              <IconButton
                aria-label="pause"
                onClick={() => setPlaying('')}
                sx={{
                  ml: 1,
                  color: 'rgba(0, 0, 0, 0.7)',
                  bgcolor: 'rgba(255, 255, 255, 0.5)',
                  '&:hover': {
                    color: 'white',
                    bgcolor: 'primary.main',
                  },
                }}
              >
                <PauseIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="play"
                onClick={() => {
                  navigate('/play?url=recommended&id=' + item?.id)
                }}
                sx={{
                  ml: 1,
                  color: 'rgba(0, 0, 0, 0.7)',
                  bgcolor: 'rgba(255, 255, 255, 0.5)',
                  '&:hover': {
                    color: 'white',
                    bgcolor: 'primary.main',
                  },
                }}
              >
                <PlayArrowIcon />
              </IconButton>
            )}
          </Box>
          <Box sx={{ position: 'absolute', bottom: 30, px: 3 }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bold', color: 'white' }}
            >
              {item?.title?.charAt(0)?.toUpperCase() +
                item?.title?.slice(1)?.toLowerCase()}
            </Typography>
            <Typography sx={{ color: 'white' }}>
              {item?.artist?.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <IconButton
                sx={{ bgcolor: { md: 'black' } }}
                onClick={(e: any) => {
                  setIsGift(false)
                  setTone(item)
                  setOpen(true)
                  setAnchorEl(e?.currentTarget)
                }}
                aria-label="purchase"
                name="purchase"
              >
                <DownloadIcon
                  sx={{
                    color: { xs: '#FFC400', md: '#8DC63F' },
                  }}
                />
              </IconButton>
              {
                // @ts-ignore
                !window?.consumerapp && (
                  <IconButton
                    sx={{ bgcolor: { md: 'black' } }}
                    onClick={(e: any) => {
                      setIsGift(true)
                      setTone(item)
                      setOpen(true)
                      setAnchorEl(e?.currentTarget)
                    }}
                    aria-label="gift"
                    name="gift"
                  >
                    <CardGiftcardIcon
                      sx={{
                        color: { xs: '#FFC400', md: '#8DC63F' },
                      }}
                    />
                  </IconButton>
                )
              }
              <IconButton
                sx={{ bgcolor: { md: 'black' } }}
                onClick={() => {
                  setTone(item)
                  setShareOpen(true)
                }}
                aria-label="share"
                name="share"
              >
                <ShareIcon
                  sx={{
                    color: { xs: '#FFC400', md: '#8DC63F' },
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

export default function TopSellings() {
  const [playing, setPlaying] = useState() as any
  const [open, setOpen] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const [isGift, setIsGift] = useState(false)
  const { setTone }: any = useContext(ToneContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()
  const { data, isLoading }: any = useFetch('topsellings', '/api/recommended')

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }

  return (
    <Box>
      {(data?.data?.length || isLoading) && (
        <Box
          sx={{
            bgcolor: { xs: 'black', md: '#8DC63F' },
            height: { xs: 305, md: 'calc(100vh - 70px)' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '100vw',
            p: { xs: 0, md: 0 },
          }}
        >
          {/* <Typography
            variant="h6"
            color="white"
            sx={{
              fontWeight: 'bold',
              px: 3,
              display: { xs: 'none', md: 'block' },
            }}
          >
            Top Sellings
          </Typography> */}
          <Box
            sx={{
              display: { xs: 'block', md: 'none' },
              height: { xs: 300, md: 'auto' },
              width: '100vw',
              overflowX: 'hidden',
              overflowY: 'hidden',
            }}
          >
            {isLoading && (
              <Skeleton
                variant="rounded"
                width="100%"
                height={250}
                sx={{ bgcolor: 'grey.900', borderRadius: 5 }}
              />
            )}
            <Carousel {...settings}>
              {data?.data?.map((item: any, index: number) => {
                if (index < 4)
                  return (
                    <Card
                      key={item?.id}
                      sx={{
                        minWidth: { xs: '90vw', md: 350 },
                        height: { xs: 250, md: 300 },
                        borderRadius: 5,
                        bgcolor: 'black',
                      }}
                      aria-hidden="true"
                    >
                      <Box
                        sx={{
                          backgroundImage: `url(${item?.poster})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: ' rgba(0,0,0,0.5)',
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            position: 'relative',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {item?.id === playing ? (
                              <IconButton
                                aria-label="pause"
                                onClick={() => setPlaying('')}
                                sx={{
                                  ml: 1,
                                  color: 'rgba(0, 0, 0, 0.7)',
                                  bgcolor: 'rgba(255, 255, 255, 0.5)',
                                  '&:hover': {
                                    color: 'white',
                                    bgcolor: 'primary.main',
                                  },
                                }}
                              >
                                <PauseIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label="play"
                                onClick={() => {
                                  navigate(
                                    '/play?url=recommended&id=' + item?.id
                                  )
                                }}
                                sx={{
                                  ml: 1,
                                  color: 'rgba(0, 0, 0, 0.7)',
                                  bgcolor: 'rgba(255, 255, 255, 0.5)',
                                  '&:hover': {
                                    color: 'white',
                                    bgcolor: 'primary.main',
                                  },
                                }}
                              >
                                <PlayArrowIcon />
                              </IconButton>
                            )}
                          </Box>
                          <Box sx={{ position: 'absolute', bottom: 30, px: 3 }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 'bold', color: 'white' }}
                            >
                              {item?.title.charAt(0).toUpperCase() +
                                item?.title.slice(1).toLowerCase()}
                            </Typography>
                            <Typography sx={{ color: 'white' }}>
                              {item?.artist?.name}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                              <IconButton
                                sx={{ bgcolor: { md: 'black' } }}
                                onClick={(e: any) => {
                                  setIsGift(false)
                                  setTone(item)
                                  setOpen(true)
                                  setAnchorEl(e?.currentTarget)
                                }}
                                aria-label="purchase"
                                name="purchase"
                              >
                                <DownloadIcon
                                  sx={{
                                    color: { xs: '#FFC400', md: '#8DC63F' },
                                  }}
                                />
                              </IconButton>
                              {
                                // @ts-ignore
                                !window?.consumerapp && (
                                  <IconButton
                                    sx={{ bgcolor: { md: 'black' } }}
                                    onClick={(e: any) => {
                                      setIsGift(true)
                                      setTone(item)
                                      setOpen(true)
                                      setAnchorEl(e?.currentTarget)
                                    }}
                                    aria-label="gift"
                                    name="gift"
                                  >
                                    <CardGiftcardIcon
                                      sx={{
                                        color: { xs: '#FFC400', md: '#8DC63F' },
                                      }}
                                    />
                                  </IconButton>
                                )
                              }
                              <IconButton
                                sx={{ bgcolor: { md: 'black' } }}
                                onClick={() => {
                                  setTone(item)
                                  setShareOpen(true)
                                }}
                                aria-label="share"
                                name="share"
                              >
                                <ShareIcon
                                  sx={{
                                    color: { xs: '#FFC400', md: '#8DC63F' },
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  )
              })}
            </Carousel>
          </Box>

          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: { md: 4 },
              alignItems: { md: 'center' },
              justifyContent: { md: 'center' },
              py: { md: 5 },
              px: { md: 7 },
              height: { xs: 300, md: 'auto' },
              width: '100vw',
              overflowX: 'hidden',
              overflowY: 'hidden',
            }}
          >
            <Box sx={{ display: 'flex', gap: 3 }}>
              {isLoading && (
                <Skeleton
                  variant="rounded"
                  width={400}
                  height={300}
                  sx={{ bgcolor: 'grey.900', borderRadius: 5 }}
                />
              )}
              {isLoading && (
                <Skeleton
                  variant="rounded"
                  width={400}
                  height={300}
                  sx={{ bgcolor: 'grey.900', borderRadius: 5 }}
                />
              )}
              {isLoading && (
                <Skeleton
                  variant="rounded"
                  width={400}
                  height={300}
                  sx={{ bgcolor: 'grey.900', borderRadius: 5 }}
                />
              )}
            </Box>
            {/* {data?.data?.map((item: any, index: number) => { */}
            {!isLoading && (
              <TopSellingWeb
                setIsGift={setIsGift}
                setTone={setTone}
                setOpen={setOpen}
                setAnchorEl={setAnchorEl}
                playing={playing}
                setPlaying={setPlaying}
                setShareOpen={setShareOpen}
                item={data?.data?.[0]}
              />
            )}
            {!isLoading && (
              <Card
                sx={{
                  minWidth: { xs: '90vw', md: 350, xl: 500 },
                  height: { xs: 250, md: 300, xl: 400 },
                  borderRadius: 5,
                  bgcolor: 'black',
                }}
              >
                <Guide />
              </Card>
            )}

            {!isLoading && (
              <TopSellingWeb
                setIsGift={setIsGift}
                setTone={setTone}
                setOpen={setOpen}
                setAnchorEl={setAnchorEl}
                playing={playing}
                setPlaying={setPlaying}
                setShareOpen={setShareOpen}
                item={data?.data?.[1]}
              />
            )}
          </Box>
        </Box>
      )}
      {(open || Boolean(anchorEl)) && (
        <RbtType
          open={open}
          closeDrawer={() => setOpen(false)}
          isGift={isGift}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
      {shareOpen && (
        <RbtShare open={shareOpen} closeDrawer={() => setShareOpen(false)} />
      )}
    </Box>
  )
}

const Carousel = styled(Slider)`
  margin-top: 20px;

  width: 100vw;

  ul li button {
    &:before {
      font-size: 10px;
      color: rgb(150, 158, 171);
    }
  }

  li.slick-active button::before {
    color: white;
  }

  .slick-list {
    overflow: visible;
  }

  button {
    z-index: 1;
  }
`
