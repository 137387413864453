import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import { Popover, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function RbtType({
  open,
  closeDrawer,
  isGift,
  activeTone,
  anchorEl,
  setAnchorEl,
}: {
  open: boolean
  closeDrawer: any
  isGift: boolean
  activeTone?: string
  anchorEl?: any
  setAnchorEl?: any
}) {
  const navigate = useNavigate()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openpopover = Boolean(anchorEl)
  const id = openpopover ? 'simple-popover' : undefined
  console.log({ openpopover })

  const list = () => (
    <Box
      sx={{ width: 'auto' }}
      role="presentation"
      onClick={closeDrawer}
      onKeyDown={closeDrawer}
    >
      <Box sx={{ p: 3 }}>
        <Typography sx={{ fontWeight: 'bold' }}>Choose RBT Type</Typography>
      </Box>
      <Divider />
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#7ACB46" />
          <stop offset={1} stopColor="#F2ED4F" />
        </linearGradient>
      </svg>
      <List>
        <ListItem>
          <ListItemButton
            onClick={() =>
              navigate(
                `/purchase?type=CALLED&gift=${isGift}&activeTone=${
                  activeTone || 'TONE1'
                }`
              )
            }
          >
            <ListItemIcon>
              <CallReceivedIcon sx={{ color: 'info.main' }} />
            </ListItemIcon>
            {isGift ? (
              <ListItemText primary="Send Gift (ለወዳጆ ያበርክቱ)" />
            ) : (
              <ListItemText primary="Set as Called RBT (ሲደወልሎት)" />
            )}
          </ListItemButton>
        </ListItem>

        <ListItem>
          {!isGift && (
            <ListItemButton
              onClick={() =>
                navigate(
                  `/purchase?type=CALLING&gift=${isGift}&activeTone=${
                    activeTone || 'TONE1'
                  }`
                )
              }
              sx={{ fontWeight: 'bold' }}
            >
              <ListItemIcon>
                <CallMadeIcon sx={{ color: 'info.main' }} />
              </ListItemIcon>
              {isGift ? (
                // <ListItemText primary="Present as Calling RBT" />
                <></>
              ) : (
                <ListItemText primary="Set as Calling RBT (ሲደውሉ)" />
              )}
            </ListItemButton>
          )}
        </ListItem>
      </List>
    </Box>
  )
  return (
    <Box>
      <Drawer
        sx={{ display: { md: 'none' } }}
        anchor="bottom"
        open={open}
        onClose={closeDrawer}
      >
        {list()}
      </Drawer>
      <Popover
        id={id}
        open={openpopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <List>
          <ListItem>
            <ListItemButton
              onClick={() =>
                navigate(
                  `/purchase?type=CALLED&gift=${isGift}&activeTone=${
                    activeTone || 'TONE1'
                  }`
                )
              }
            >
              <ListItemIcon>
                <CallReceivedIcon sx={{ color: '#8DC63F' }} />
              </ListItemIcon>
              {isGift ? (
                <ListItemText primary="Send Gift(ለወዳጆ ያበርክቱ)" />
              ) : (
                <ListItemText primary="Set as Called RBT(ሲደወልሎት)" />
              )}
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() =>
                navigate(
                  `/purchase?type=CALLING&gift=${isGift}&activeTone=${
                    activeTone || 'TONE1'
                  }`
                )
              }
              sx={{ fontWeight: 'bold' }}
            >
              <ListItemIcon>
                <CallMadeIcon sx={{ color: '#8DC63F' }} />
              </ListItemIcon>
              {isGift ? (
                // <ListItemText primary="Present as Calling RBT" />
                <></>
              ) : (
                <ListItemText primary="Set as Calling RBT(ሲደውሉ)" />
              )}
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </Box>
  )
}
