import { Box, CircularProgress } from '@mui/material'

export default function PageLoading() {
  return (
    <Box
      height="90vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress sx={{ position: 'absolute' }} />
    </Box>
  )
}
