export const getCustomer = () => {
  const item = localStorage.getItem('Customer') as string
  return JSON.parse(item)
}

export const authHeader = () => {
  const customer = getCustomer()
  if (customer) {
    return { Authorization: 'Bearer ' + customer.accessToken }
  } else {
    return {}
  }
}

export const logout = () => {
  localStorage.removeItem('Customer')
}
