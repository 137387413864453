import { Box, Card, IconButton, Skeleton, Typography } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import ShareIcon from '@mui/icons-material/Share'
import { useContext, useState } from 'react'
import { ToneContext } from '../../context/toneContext'
import RbtType from '../RbtType'
import { useFetch } from '../../hooks/useFetch'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { useNavigate } from 'react-router-dom'
import RbtShare from '../RBTShare'

export default function New() {
  const sorting = [{ id: 'createdAt', desc: false }]
  const { data, isLoading }: any = useFetch('new', '/api/music', {
    sorting,
    pagination: { pageIndex: 0, pageSize: 10 },
  })

  const [open, setOpen] = useState(false)
  const [isGift, setIsGift] = useState(false)
  const { setTone }: any = useContext(ToneContext)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const [shareOpen, setShareOpen] = useState(false)
  return (
    <Box sx={{ my: { md: 7 } }}>
      {(data?.data?.length || isLoading) && (
        <Box>
          <Box sx={{ mx: { xs: 2, md: 7 } }}>
            <Typography sx={{ fontWeight: 'bold' }}>New</Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                py: 2,
                px: { xs: 2, md: 7 },
                gap: { xs: 3, md: 5 },
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              {isLoading && (
                <Skeleton
                  variant="rounded"
                  width={300}
                  height={250}
                  sx={{ borderRadius: 5 }}
                />
              )}
              {data?.data?.map((item: any) => (
                <Box
                  key={item?.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: { xs: 270, xl: 400 },
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      mt: 1,
                      borderRadius: 2,
                      mr: 2,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        height: { xs: 170 },
                        borderTopLeftRadius: 2,
                        borderTopRightRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                    >
                      <img
                        width="100%"
                        height="100%"
                        alt={item?.title}
                        src={item?.poster}
                        loading="lazy"
                        style={{
                          position: 'absolute',
                          objectFit: 'cover',
                        }}
                      />
                      <IconButton
                        aria-label="play"
                        onClick={() => {
                          navigate(
                            '/play?url=music&id=' +
                              item?.id +
                              '&sorting=' +
                              JSON.stringify(sorting)
                          )
                        }}
                        sx={{
                          ml: 1,
                          color: 'rgba(255, 255, 255, 0.5)',
                          bgcolor: 'rgba(0, 0, 0, 0.5)',
                          '&:hover': {
                            color: 'white',
                            bgcolor: 'primary.main',
                          },
                          zIndex: 1,
                        }}
                      >
                        <PlayArrowIcon />
                      </IconButton>
                      <Box
                        sx={{
                          height: 120,
                          position: 'absolute',
                          bottom: 0,
                          width: '100%',
                          backgroundImage:
                            'linear-gradient(180deg,transparent,rgba(255, 255, 255, 0.6),white)',
                        }}
                      >
                        <Box
                          sx={{
                            px: 2,
                            py: 5,
                            position: 'absolute',
                            bottom: -60,
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: '900',
                              fontFamily: 'sans-serif',
                              lineHeight: 1,
                            }}
                          >
                            {item?.title.charAt(0).toUpperCase() +
                              item?.title.slice(1).toLowerCase()}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: 'monospace',
                            }}
                          >
                            {item?.artist?.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        pb: 2,
                        px: 1,
                        mt: 5,
                      }}
                    >
                      <svg width={0} height={0}>
                        <linearGradient
                          id="linearColors"
                          x1={1}
                          y1={0}
                          x2={1}
                          y2={1}
                        >
                          <stop offset={0} stopColor="#7ACB46" />
                          <stop offset={1} stopColor="#F2ED4F" />
                        </linearGradient>
                      </svg>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box>
                          <IconButton
                            aria-label="purchase"
                            onClick={(e: any) => {
                              setIsGift(false)
                              setTone(item)
                              setOpen(true)
                              setAnchorEl(e?.currentTarget)
                            }}
                            sx={{
                              bgcolor: '#F5F5F5',
                              color: '#8DC63F',
                              '&:hover': {
                                color: 'white',
                                bgcolor: '#8DC63F',
                              },
                            }}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Box>
                        <Box sx={{ ml: { xs: 1, xl: 3 } }}>
                          {
                            // @ts-ignore
                            !window?.consumerapp && (
                              <IconButton
                                aria-label="gift"
                                onClick={(e: any) => {
                                  setIsGift(true)
                                  setTone(item)
                                  setOpen(true)
                                  setAnchorEl(e?.currentTarget)
                                }}
                                sx={{
                                  bgcolor: '#F5F5F5',
                                  color: '#8DC63F',
                                  '&:hover': {
                                    color: 'white',
                                    bgcolor: '#8DC63F',
                                  },
                                }}
                              >
                                <CardGiftcardIcon />
                              </IconButton>
                            )
                          }
                        </Box>
                        <Box sx={{ mx: { xs: 1, xl: 3 } }}>
                          <IconButton
                            aria-label="share"
                            sx={{
                              bgcolor: '#F5F5F5',
                              color: '#8DC63F',
                              '&:hover': {
                                color: 'white',
                                bgcolor: '#8DC63F',
                              },
                            }}
                            onClick={() => {
                              setTone(item)
                              setShareOpen(true)
                            }}
                          >
                            <ShareIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      {open && (
        <RbtType
          open={open}
          closeDrawer={() => setOpen(false)}
          isGift={isGift}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
      {shareOpen && (
        <RbtShare open={shareOpen} closeDrawer={() => setShareOpen(false)} />
      )}
    </Box>
  )
}
