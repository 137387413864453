import { Box, Button, Grid, Typography } from '@mui/material'
import GridViewIcon from '@mui/icons-material/GridView'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'

function NavItem({
  linkTo,
  text,
  icon,
  end,
  activeIcon,
}: {
  linkTo: string
  text: string
  icon: any
  end?: boolean
  activeIcon: any
}) {
  const [active, setActive] = useState(false)
  return (
    <Grid item xs={3} sx={{ color: 'white' }}>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          aria-label="menu"
          component={NavLink}
          to={linkTo}
          end={end}
          //@ts-ignore
          style={({ isActive }) => {
            isActive ? setActive(true) : setActive(false)
            return isActive
              ? {
                  background:
                    '-webkit-linear-gradient(180deg, #7ACB46 0%, #F2ED4F 100%)',
                  '-webkit-background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent',
                }
              : { color: '#BEBEBE' }
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'inherit',
            fill: 'inherit',
          }}
        >
          {active ? activeIcon : icon}
          <Typography variant="caption">{text}</Typography>
        </Button>
      </Box>
    </Grid>
  )
}

export default function BottomNav() {
  return (
    <Box
      sx={{
        display: { md: 'none' },
        position: 'fixed',
        bottom: 0,
        width: '100vw',
        height: 70,
        bgcolor: '#000000',
      }}
    >
      <Grid container sx={{ height: '100%' }}>
        <svg width={0} height={0}>
          <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
            <stop offset={0} stopColor="#7ACB46" />
            <stop offset={1} stopColor="#F2ED4F" />
          </linearGradient>
        </svg>
        <NavItem
          text="Home"
          linkTo="/"
          icon={<GridViewIcon />}
          activeIcon={<GridViewIcon sx={{ fill: 'url(#linearColors)' }} />}
          end={true}
        />
        <NavItem
          text="Artists"
          linkTo="/artists"
          icon={<MusicNoteIcon />}
          activeIcon={<MusicNoteIcon sx={{ fill: 'url(#linearColors)' }} />}
        />
        <NavItem
          text="Rank"
          linkTo="/ranking"
          icon={<StarOutlineIcon />}
          activeIcon={<StarOutlineIcon sx={{ fill: 'url(#linearColors)' }} />}
        />
        {/* <NavItem
          text="Reward"
          linkTo="/reward"
          icon={<MilitaryTechOutlinedIcon />}
          activeIcon={
            <MilitaryTechOutlinedIcon sx={{ fill: 'url(#linearColors)' }} />
          }
        /> */}
        <NavItem
          text="Account"
          linkTo="/account"
          icon={<PersonOutlineIcon />}
          activeIcon={<PersonOutlineIcon sx={{ fill: 'url(#linearColors)' }} />}
        />
      </Grid>
    </Box>
  )
}
