import { Suspense, lazy } from 'react'
import { Box, Skeleton } from '@mui/material'
import { Outlet } from 'react-router-dom'
import BottomNav from './BottomNav'
import { debounce } from '../../pages/Play'
import InstallPrompt from '../InstallPrompt'
import useAddToHomescreenPrompt from '../../hooks/useAddToHomeScreen'
const Navbar = lazy(() => import('./Navbar'))

const postHandler = (musics: any) => {
  if ('serviceWorker' in navigator) {
    const sw = navigator.serviceWorker.controller
    sw?.postMessage({
      type: `PREFETCH_MEDIA`,
      data: musics?.data?.data?.reduce(
        (prev: any, curr: any) => [
          ...prev,
          ...curr.Tone.map((el: any) => el.streamUrl),
        ],
        []
      ),
      images: musics?.data?.data?.reduce(
        (prev: any, curr: any) => [...prev, curr.poster],
        []
      ),
      meta: musics?.data?.metaData,
    })
  }
}

export const debouncedHandler = debounce(postHandler, 500)

export default function Layout() {
  const [prompt, setState, promptToInstall] = useAddToHomescreenPrompt()

  return (
    <Box>
      <InstallPrompt
        prompt={prompt}
        setState={setState}
        promptToInstall={promptToInstall}
      />
      <Box display={{ md: 'block', xs: 'none' }}>
        <Suspense
          fallback={<Skeleton variant="rounded" width="100vw" height={70} />}
        >
          <Navbar />
        </Suspense>
      </Box>
      <Box sx={{ mt: { md: '70px' }, height: { md: 'calc(100svh - 70px)' } }}>
        <Outlet />
      </Box>
      <BottomNav />
    </Box>
  )
}
