import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useFetch } from '../../hooks/useFetch'
import Guide from '../Guide'

export default function Featured() {
  const navigate = useNavigate()
  const { data, isLoading }: any = useFetch('featured', '/api/music', {
    sorting: [{ id: 'order', desc: false }],
  })

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      {
        <Box sx={{ my: 2, mx: 1 }}>
          {/* <Box sx={{ mt: 2, mx: 2 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Featured</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              minWidth: '100vw',
              p: 2,
              gap: 2,
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                width: 0,
              },
            }}
          >
            {isLoading && (
              <Skeleton
                variant="rounded"
                width="100%"
                height={200}
                sx={{ borderRadius: 5 }}
              />
            )}
            {data?.data?.length &&
              data?.data?.map((item: any) => (
                <Card
                  key={item?.id}
                  sx={{
                    height: 200,
                    minWidth: '100%',
                    borderRadius: 5,
                    bgcolor: 'black',
                    mr: 2,
                    position: 'relative',
                  }}
                  onClick={() => {
                    navigate(
                      '/play?url=music&id=' +
                        item?.id +
                        '&sorting=' +
                        JSON.stringify([{ id: 'order', desc: false }])
                    )
                  }}
                >
                
                  <img
                    width="100%"
                    height="100%"
                    alt={item?.name}
                    src={item?.poster}
                    loading="lazy"
                    style={{
                      position: 'absolute',
                      objectFit: 'cover',
                    }}
                  />
                </Card>
              ))}
          </Box> */}
          <Guide />
        </Box>
      }
    </Box>
  )
}
