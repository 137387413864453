import { Suspense, lazy } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { getCustomer } from '../../utils/auth'
const Login = lazy(() => import('../../pages/Login'))

export function CheckAuth({ children }: any) {
  const user = getCustomer()
  if (!user) {
    return (
      <Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>{children}</Box>
        <Box sx={{ display: { md: 'none' } }}>
          <Suspense fallback={<CircularProgress />}>
            <Login />
          </Suspense>
        </Box>
      </Box>
    )
  }
  return children
}
