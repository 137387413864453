import { Box, Typography } from '@mui/material'
import Lottie from 'lottie-react'
import audiowave from '../assets/animations/fire.json'
import background from '../assets/images/background2.png'
import { Download } from '@mui/icons-material'

export default function Guide() {
  return (
    <Box
      sx={{
        height: '100%',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        // backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        py: 2,
        borderRadius: 3,
      }}
    >
      <Lottie
        animationData={audiowave}
        style={{ width: 300, height: 300, position: 'absolute' }}
      />
      <Typography
        variant="h5"
        sx={{
          textAlign: 'center',
          px: 3,
          color: 'white',
          fontSize: { xs: 17, md: 19 },
          fontWeight: 'bold',
        }}
      >
        እንኳን ደህና መጡ
      </Typography>
      <Typography
        sx={{
          px: 4,
          textAlign: 'center',
          py: 1,
          color: 'white',
          fontSize: { xs: 15, md: 16 },
        }}
      >
        srbt.et ደንበኞች የስልክ መደወያ ጥሪን ለማሣመር በቀላሉ እንደየፍላጎታቸው እንዲመርጡ የተዘጋጀ website
        ነው።
      </Typography>
      <Typography
        sx={{
          px: 4,
          textAlign: 'center',
          py: 1,
          color: 'white',
          fontSize: { xs: 15, md: 16 },
        }}
      >
        1. በቅድሚያ ለጥሪ የሚፈልጉትን ሙዚቃ ይለዩ። <br /> 2. በመቀጠልም የሚፈልጉት ሙዚቃ ላይ ያለዉን የ
        <Download sx={{ height: 15 }} /> ምልክት በመጫን እና register በማድረግ ሙዚቃውን ይምረጡ።
      </Typography>
      {/* <Typography
        sx={{
          px: 4,
          textAlign: 'center',
          py: 2,
          color: 'white',
          fontSize: { xs: 15, md: 16 },
        }}
      >
        srbt.et ደንበኞች የስልክ መደወያ ጥሪን ለማሣመር በቀላሉ እንደየፍላጎታቸው እንዲመርጡ የተዘጋጀ website
        ነው። በቅድሚያ ለጥሪ የሚፈልጉትን ሙዚቃ ይለዩ። በመቀጠልም የሚፈልጉት ሙዚቃ ላይ ያለዉን የ
        <Download sx={{ height: 15 }} />
        {/* <span>
          <Download sx={{ height: 20, border: '1px solid red' }} />
        </span>{' '} */}
      {/* ምልክት በመጫን እና register በማድረግ ሙዚቃውን ይምረጡ። */}
      {/* </Typography> */}
    </Box>
  )
}
